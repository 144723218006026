import { z } from "zod";

export const BaseEventSchema = z.object({
  id: z.string().uuid(),
  type: z.string().min(1),
  data: z.optional(z.undefined()),
});

export const HandlerFiredEventSchema = BaseEventSchema.extend({
  type: z.literal("HANDLER_FIRED"),
  data: z.object({
    handlerId: z.string().min(1),
    payload: z.any(),
  }),
});
export type HandlerFiredEvent = z.infer<typeof HandlerFiredEventSchema>;

export const ItemRunFiredEventSchema = BaseEventSchema.extend({
  type: z.literal("ITEM_RUN_FIRED"),
  data: z.any(),
});
export type ItemRunFiredEvent = z.infer<typeof ItemRunFiredEventSchema>;

export const DataRequestedEventSchema = BaseEventSchema.extend({
  type: z.literal("DATA_REQUESTED"),
  data: z.object({
    dataSourceId: z.string().min(1),
    restartPaging: z.optional(z.boolean()),
  }),
});
export type DataRequestedEvent = z.infer<typeof DataRequestedEventSchema>;

export const ViewIdSchema = z.enum(["ITEM_CONFIG_VIEW", "ITEM_RUN_VIEW"]);
export type ViewId = z.infer<typeof ViewIdSchema>;
export const RenderViewEventSchema = BaseEventSchema.extend({
  type: z.literal("RENDER_VIEW"),
  data: z.object({ viewId: ViewIdSchema }),
});
export type RenderViewEvent = z.infer<typeof RenderViewEventSchema>;

export const EventSchema = z.discriminatedUnion("type", [
  HandlerFiredEventSchema,
  DataRequestedEventSchema,
  RenderViewEventSchema,
  ItemRunFiredEventSchema,
]);

export type CustomItemEvent = z.infer<typeof EventSchema>;

/*
  Move to these events:
   ITEM_RUN_FIRED
   COMPONENT_HANDLER_FIRED
   WEBHOOK_RECEIVED
   DATA_REQUESTED
   VIEW_REQUESTED
 */
