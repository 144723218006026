import { parse as parseComments } from "comment-parser";
import { AppId } from "@questmate/openapi-spec";

const ensureFullListOfAppIds = <T extends AppId[]>(
  array: T & ([AppId] extends [T[number]] ? unknown : "Invalid")
) => array;

const SUPPORTED_EXTERNAL_APPS = ensureFullListOfAppIds([
  "tremendous",
  "slack",
  "airtable",
  "hue",
  "google",
  "spotify",
  "hubspot",
  "tesla",
  "nexudus",
  "pipedrive",
  "notion",
  "atlassian",
  "microsoft",
  "ewelink",
]);

export type ExternalAuthorizableApp = typeof SUPPORTED_EXTERNAL_APPS[number];

export type AppPermissionRequest = {
  name: ExternalAuthorizableApp;
  scopes: string[];
};
export function parseRequestedAppScopes(
  script: string
): AppPermissionRequest[] {
  const requestedApps: AppPermissionRequest[] = [];
  const parsingProblems = [];
  try {
    const commentBlocks = parseComments(script);
    for (const block of commentBlocks) {
      if (block.problems.length > 0) {
        parsingProblems.push(...block.problems);
        continue;
      }
      for (const tag of block.tags) {
        if (tag.problems.length > 0) {
          parsingProblems.push(...tag.problems);
          continue;
        }

        if (tag.tag === "UseApp") {
          const { type: _appName, name: scope } = tag;
          const appName = _appName as ExternalAuthorizableApp;

          const matchingAppId = SUPPORTED_EXTERNAL_APPS.find(
            (appId) => appId.toLowerCase() === appName?.toLowerCase()?.trim()
          );

          if (!matchingAppId) {
            parsingProblems.push('Unsupported app "' + appName + '"');
            continue;
          }

          let entry = requestedApps.find(({ name }) => name === matchingAppId);
          if (!entry) {
            entry = {
              name: matchingAppId,
              scopes: [],
            };
            requestedApps.push(entry);
          }
          if (scope) {
            entry.scopes.push(scope);
          }
        }
      }
    }
  } catch (e) {
    console.error("failed to parse comments!", e);
    parsingProblems.push("Failed to parse comments:", e);
  }
  if (parsingProblems.length > 0) {
    console.log(
      "Errors while parsing QuestScript JSDoc comments: ",
      JSON.stringify(parsingProblems)
    );
    throw new Error(
      `Errors while parsing QuestScript JSDoc comments:\n    ${parsingProblems.join(
        "\n    "
      )}`
    );
  }

  return requestedApps;
}
