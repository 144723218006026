import { z } from "zod";
import {
  ComponentDefinitionSchema,
  InlineComponentDefinitionSchema,
} from "./CustomItemComponentDefinitions";
import { BaseEventSchema } from "./CustomItemEvents";

export const DependentDataSourceSchema = z.object({
  id: z.string().min(1),
  refreshInterval: z.optional(z.number().min(10)),
  lastUpdated: z.string({ description: "ISO DateTime String" }).or(z.null()),
  hasMoreData: z.boolean(),
  error: z.optional(
    z.object({
      message: z
        .string({
          description:
            "User friendly message describing the issue that caused the error.",
        })
        .catch("Unknown Error"),
      retryAfter: z.optional(z.number().nullable(), {
        description: "Number of seconds to wait before retrying.",
      }),
    })
  ),
});
export type DependentDataSource = z.infer<typeof DependentDataSourceSchema>;

export const EventReportSchema = BaseEventSchema.pick({
  id: true,
  type: true,
}).extend({
  status: z.enum(["SUCCESS", "ERROR", "UNPROCESSED"]),
  logs: z.array(z.string()),
  message: z
    .string()
    .optional()
    .describe(
      "User friendly message that will be displayed in a popup snackbar message. Color based on status. Only present on action handler event reports."
    ),
});
export type EventReport = z.infer<typeof EventReportSchema>;

export const HydratedViewSchema = z.object({
  isRegistered: z.literal(true),
  components: z.array(ComponentDefinitionSchema),
  inlineComponent: z.optional(InlineComponentDefinitionSchema),
});
export type HydratedView = z.infer<typeof HydratedViewSchema>;

export const UnregisteredViewSchema = z.object({
  isRegistered: z.literal(false),
});
export type UnregisteredView = z.infer<typeof UnregisteredViewSchema>;

export const RenderViewResultSchema = z.discriminatedUnion("isRegistered", [
  HydratedViewSchema,
  UnregisteredViewSchema,
]);
export type RenderViewResult = z.infer<typeof RenderViewResultSchema>;

export const CustomItemV2ResultSchema = z.object({
  state: z.object({
    CONFIG_DATA: z.record(z.unknown()),
    RUN_DATA: z.optional(z.record(z.unknown())),
  }),
  views: z.record(RenderViewResultSchema),
  dependentDataSources: z.record(z.string(), DependentDataSourceSchema),
  eventReports: z.array(EventReportSchema),
});
export type CustomItemV2Result = z.infer<typeof CustomItemV2ResultSchema>;
