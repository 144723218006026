import { z } from "zod";

export const BaseComponentDefinitionSchema = z.object({
  id: z.string().min(1),
  key: z.optional(z.string().min(1), {
    //todo #314 omitting the 'key' field will remove it when parsing the schema.
    //     Leave it in until all front-ends have been updated to use the first beta release of v2
    description: 'DEPRECATED: Use "id" instead.',
  }),
  type: z.string().min(1),
  title: z.optional(z.string().min(1)),
  dependentDataSourceIds: z.optional(z.array(z.string())),
});

const HandlerIDSchema = z.string().min(1);
function buildHandlerIdMapSchema<
  T extends Record<string, "REQUIRED" | "OPTIONAL">
>(handlers: T): z.ZodOptional<z.ZodObject<{ [K in keyof T]: z.ZodTypeAny }>> {
  return z.optional(
    z.object(
      Object.entries(handlers).reduce((acc, [handlerName, configuration]) => {
        if (handlerName && configuration) {
          acc[handlerName as keyof T] =
            configuration === "REQUIRED"
              ? HandlerIDSchema
              : HandlerIDSchema.optional();
        }
        return acc;
      }, {} as { [K in keyof T]: z.ZodTypeAny })
    )
  );
}

export const BaseInteractiveComponentDefinitionSchema =
  BaseComponentDefinitionSchema.extend({
    disabled: z.optional(z.boolean()),
    handlers: z.optional(z.record(HandlerIDSchema)),
  });
export type BaseInteractiveComponentDefinition = z.infer<
  typeof BaseInteractiveComponentDefinitionSchema
>;

export const DropdownValueSchema = z.union([
  z.string().min(1),
  z.number(),
  z.null(),
]);
export const DropdownOptionsSchema = z
  .array(
    z.object({
      label: z.string().min(1),
      value: DropdownValueSchema,
    })
  )
  .superRefine((options, ctx) => {
    for (let i = 0; i < options.length; i++) {
      for (let j = i + 1; j < options.length; j++) {
        if (options[i].value === options[j].value) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: [i, "value"],
            message: `All Dropdown option values must be unique. Found two options with the same \`value\`: ${options[i].value}`,
            params: {
              optionA: options[i],
              optionB: options[j],
              optionAIndex: i,
              optionBIndex: j,
            },
          });
        }
      }
    }
  });

export const DropdownComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("dropdown"),
    value: z.optional(DropdownValueSchema),
    options: DropdownOptionsSchema,
    data: z.optional(
      DropdownOptionsSchema,
      //todo #314 omitting the 'data' field will remove it when parsing the schema.
      //     Leave it in until all front-ends have been updated to use the first beta release of v2
      { description: "DEPRECATED: Use `options` instead." }
    ),
    placeholderIcon: z.optional(z.string().min(1)),
    optionNoun: z.optional(z.string().min(1)),
    optionPluralNoun: z.optional(z.string().min(1)),
    autoFocus: z.optional(z.boolean()),
    handlers: buildHandlerIdMapSchema({
      onSelect: "OPTIONAL",
      onSearch: "OPTIONAL",
    }),
  });
export type DropdownComponentDefinition = z.infer<
  typeof DropdownComponentDefinitionSchema
>;

export const SwitchComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("switch"),
    handlers: buildHandlerIdMapSchema({
      onSwitch: "OPTIONAL",
    }),
    value: z.optional(z.coerce.boolean()),
  });
export type SwitchComponentDefinition = z.infer<
  typeof SwitchComponentDefinitionSchema
>;

export const ButtonComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("button"),
    buttonLabel: z.string().min(1),
    handlers: buildHandlerIdMapSchema({
      onPress: "OPTIONAL",
    }),
    success: z.optional(z.boolean()),
  });
export type ButtonComponentDefinition = z.infer<
  typeof ButtonComponentDefinitionSchema
>;

export const GenericLinkableLocationSchema = z.union([
  z.string().url(),
  z.object({
    type: z.optional(
      z.enum([
        "NAVIGATE",
        "REPLACE",
        "PUSH",
        // "GO_BACK",
        // "RESET",
        // "SET_PARAMS",
        // "POP",
        // "POP_TO_TOP",
      ])
    ),
    screen: z.string().min(1),
    params: z.optional(z.record(z.any())),
  }),
]);
export type GenericLinkableLocation = z.infer<
  typeof GenericLinkableLocationSchema
>;

export const NavigationActionComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.omit({ handlers: true }).extend({
    type: z.literal("NavigationAction"),
    labelText: z.string().min(1),
    to: GenericLinkableLocationSchema,
    newTabOnWeb: z.optional(z.boolean()),
    autoRedirectDelay: z.optional(z.number().min(1).max(60)),
  });
export type NavigationActionComponentDefinition = z.infer<
  typeof NavigationActionComponentDefinitionSchema
>;

export const TextComponentDefinitionSchema =
  BaseComponentDefinitionSchema.extend({
    type: z.literal("text"),
    content: z.coerce.string().min(1),
    contentColor: z.optional(
      z.union([z.literal("normal"), z.literal("warning")])
    ),
  });
export type TextComponentDefinition = z.infer<
  typeof TextComponentDefinitionSchema
>;

export const CopyTextComponentDefinitionSchema =
  BaseComponentDefinitionSchema.extend({
    type: z.literal("CopyText"),
    content: z.coerce.string().min(1),
  });
export type CopyTextComponentDefinition = z.infer<
  typeof CopyTextComponentDefinitionSchema
>;

export const ShortAnswerComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("ShortAnswer"),
    value: z.coerce.string(),
    placeholder: z.optional(z.string().min(1)),
    secure: z.optional(z.boolean()),
    handlers: buildHandlerIdMapSchema({
      onChange: "OPTIONAL",
    }),
  });
export type ShortAnswerComponentDefinition = z.infer<
  typeof ShortAnswerComponentDefinitionSchema
>;

export const LongAnswerComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("LongAnswer"),
    value: z.coerce.string(),
    placeholder: z.optional(z.string().min(1)),
    handlers: buildHandlerIdMapSchema({
      onChange: "OPTIONAL",
    }),
  });
export type LongAnswerComponentDefinition = z.infer<
  typeof LongAnswerComponentDefinitionSchema
>;

export const ItemPickerComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("ItemPicker"),
    handlers: buildHandlerIdMapSchema({
      onSelect: "OPTIONAL",
    }),
    value: z.optional(z.coerce.string()),
  });
export type ItemPickerComponentDefinition = z.infer<
  typeof ItemPickerComponentDefinitionSchema
>;

export const QuestDataItemIdentifierSchema = z.object({
  itemId: z.string().min(1),
  selectedDataPath: z.optional(z.string().min(1)),
});
export type QuestDataItemIdentifier = z.infer<
  typeof QuestDataItemIdentifierSchema
>;
export const QuestDataContextIdentifierSchema = z.object({
  contextKey: z.string().min(1),
});
export type QuestDataContextIdentifier = z.infer<
  typeof QuestDataContextIdentifierSchema
>;
export const QuestDataExpressionIdentifierSchema = z.object({
  exp: z.string(),
});
export type QuestDataExpressionIdentifier = z.infer<
  typeof QuestDataExpressionIdentifierSchema
>;
export const QuestDataIdentifierSchema = z.union([
  QuestDataItemIdentifierSchema,
  QuestDataContextIdentifierSchema,
  QuestDataExpressionIdentifierSchema,
]);
export type QuestDataIdentifier = z.infer<typeof QuestDataIdentifierSchema>;

export function isItemIdentifier(
  dataIdentifier: QuestDataIdentifier | null | undefined
): dataIdentifier is QuestDataItemIdentifier {
  return (
    !!dataIdentifier &&
    typeof dataIdentifier === "object" &&
    "itemId" in dataIdentifier &&
    !!dataIdentifier.itemId
  );
}
export function isContextIdentifier(
  dataIdentifier: QuestDataIdentifier | null | undefined
): dataIdentifier is QuestDataContextIdentifier {
  return (
    !!dataIdentifier &&
    typeof dataIdentifier === "object" &&
    "contextKey" in dataIdentifier &&
    !!dataIdentifier.contextKey
  );
}
export function isExpressionIdentifier(
  dataIdentifier: QuestDataIdentifier | null | undefined
): dataIdentifier is QuestDataExpressionIdentifier {
  return (
    !!dataIdentifier &&
    typeof dataIdentifier === "object" &&
    "exp" in dataIdentifier &&
    typeof dataIdentifier.exp === "string"
  );
}

export const QuestDataPickerComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.extend({
    type: z.literal("QuestDataPicker"),
    handlers: buildHandlerIdMapSchema({
      onSelect: "OPTIONAL",
    }),
    value: QuestDataIdentifierSchema.nullable().optional(),
  });
export type QuestDataPickerComponentDefinition = z.infer<
  typeof QuestDataPickerComponentDefinitionSchema
>;
export const CheckableComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.omit({ title: true }).extend({
    type: z.literal("Checkable"),
    title: z.string().min(1),
    handlers: buildHandlerIdMapSchema({
      onChange: "REQUIRED",
    }),
    value: z.optional(z.coerce.boolean()),
  });
export type CheckableComponentDefinition = z.infer<
  typeof CheckableComponentDefinitionSchema
>;

export const BaseCardSubComponentDefinitionSchema = z.object({
  id: z.string().min(1),
  type: z.string().min(1),
  align: z.enum(["left", "center", "right"]).optional(),
  width: z.enum(["expand", "collapse"]).optional(),
});

export const CardImageSubComponentDefinitionSchema =
  BaseCardSubComponentDefinitionSchema.extend({
    type: z.literal("Image"),
    url: z.string().min(1),
  });
export type CardImageSubComponentDefinition = z.infer<
  typeof CardImageSubComponentDefinitionSchema
>;
export const CardTextSubComponentDefinitionSchema =
  BaseCardSubComponentDefinitionSchema.extend({
    type: z.literal("Text"),
    content: z.string(),
    bold: z.boolean().optional(),
  });
export type CardTextSubComponentDefinition = z.infer<
  typeof CardTextSubComponentDefinitionSchema
>;

export const CardSubComponentDefinitionSchema = z.discriminatedUnion("type", [
  CardImageSubComponentDefinitionSchema,
  CardTextSubComponentDefinitionSchema,
]);
export type CardSubComponentDefinition = z.infer<
  typeof CardSubComponentDefinitionSchema
>;

export const CardColumnSubComponentDefinitionSchema =
  BaseCardSubComponentDefinitionSchema.extend({
    type: z.literal("Column"),
    components: z.array(CardSubComponentDefinitionSchema).min(1).max(2),
  });
export type CardColumnSubComponentDefinition = z.infer<
  typeof CardColumnSubComponentDefinitionSchema
>;

export const CardRowLinkSchema = z.object({
  // hint: z.optional(z.string()),
  to: GenericLinkableLocationSchema,
  newTabOnWeb: z.optional(z.boolean()),
});
export type CardRowLink = z.infer<typeof CardRowLinkSchema>;

export const CardRowComponentDefinitionSchema = z.object({
  id: z.string().min(1),
  type: z.literal("Row"),
  link: z.optional(CardRowLinkSchema),
  components: z.array(
    z.discriminatedUnion("type", [
      CardColumnSubComponentDefinitionSchema,
      ...CardSubComponentDefinitionSchema.options,
    ])
  ),
});
export type CardRowComponentDefinition = z.infer<
  typeof CardRowComponentDefinitionSchema
>;

export const CardComponentDefinitionSchema =
  BaseComponentDefinitionSchema.extend({
    type: z.literal("Card"),
    rows: z.array(CardRowComponentDefinitionSchema).min(1),
  });
export type CardComponentDefinition = z.infer<
  typeof CardComponentDefinitionSchema
>;

export const ComponentDefinitionSchema = z.discriminatedUnion("type", [
  DropdownComponentDefinitionSchema,
  SwitchComponentDefinitionSchema,
  ButtonComponentDefinitionSchema,
  NavigationActionComponentDefinitionSchema,
  TextComponentDefinitionSchema,
  CopyTextComponentDefinitionSchema,
  ItemPickerComponentDefinitionSchema,
  QuestDataPickerComponentDefinitionSchema,
  ShortAnswerComponentDefinitionSchema,
  LongAnswerComponentDefinitionSchema,
  CheckableComponentDefinitionSchema,
  CardComponentDefinitionSchema,
]);
export type ComponentDefinition = z.infer<typeof ComponentDefinitionSchema>;

const BaseInlineComponentDefinitionSchema =
  BaseInteractiveComponentDefinitionSchema.omit({ title: true });

export const CheckableInlineComponentDefinitionSchema =
  BaseInlineComponentDefinitionSchema.extend({
    type: z.literal("Checkable"),
    handlers: buildHandlerIdMapSchema({
      onChange: "REQUIRED",
    }),
    value: z.optional(z.coerce.boolean()),
  });
export type CheckableInlineComponentDefinition = z.infer<
  typeof CheckableInlineComponentDefinitionSchema
>;

export const AudioInlineComponentDefinitionSchema =
  BaseInlineComponentDefinitionSchema.extend({
    type: z.literal("Audio"),
    url: z.string(),
    trackTitle: z.string(),
    artist: z.string(),
    artwork: z.string().optional(),
    handlers: buildHandlerIdMapSchema({}),
  });
export type AudioInlineComponentDefinition = z.infer<
  typeof AudioInlineComponentDefinitionSchema
>;

export const InlineComponentDefinitionSchema = z.discriminatedUnion("type", [
  CheckableInlineComponentDefinitionSchema,
  AudioInlineComponentDefinitionSchema,
]);
export type InlineComponentDefinition = z.infer<
  typeof InlineComponentDefinitionSchema
>;
