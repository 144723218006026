import { type ItemType } from "@questmate/openapi-spec";
import { type MediaItem } from "@questmate/common";

/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches a JSON object.
 * This type can be useful to enforce some input to be JSON-compatible or as a super-type to be extended from.
 */
export type JsonObject = { [Key in string]?: JsonValue };

/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches a JSON array.
 */
export interface JsonArray extends Array<JsonValue> {}

/**
 * From https://github.com/sindresorhus/type-fest/
 * Matches any valid JSON value.
 */
export type JsonValue =
  | string
  | number
  | boolean
  | JsonObject
  | JsonArray
  | null;

type BaseItemData = {
  id: string;
  name: string;
  // type: ItemType;
  referenceSlug: string | null;
  subquestIds?: string[];
  version: number;
};

export type QuestScriptMediaItem = MediaItem & {
  // The URL we provide to QuestScript is a time-limited signed url.
  url: string;
};

interface FileUploadItem extends BaseItemData {
  type: "FileUpload";
  data: { mediaItems: QuestScriptMediaItem[] };
}

interface OtherItem extends BaseItemData {
  type: Omit<ItemType, "FileUpload">;
  data: JsonObject | null;
}

export type ItemData = OtherItem | FileUploadItem;

export type NormalizedQuestInstanceData = {
  instanceId: string; //todo: rename to runId
  prototypeId: string;
  name: string;
  parentId?: string;
  items: ItemData[];
  submittedBy?: {
    id: string;
    displayName: string;
  };
};

export type NormalizedQuestContext = {
  quest: NormalizedQuestInstanceData;
  quests: { [k: string]: NormalizedQuestInstanceData };
};

export const QuestDataContextKeyList = [
  "runName",
  "completedAt",
  "submittedBy",
] as const;
export type QuestDataContextKey = typeof QuestDataContextKeyList[number];

export type QuestRunExpressionItemsRootKey = "items";
export type QuestRunExpressionRootKey =
  | QuestDataContextKey
  | "runId"
  | QuestRunExpressionItemsRootKey;
