import {
  parse as parseComments,
  stringify,
  transforms,
  util,
} from "comment-parser";

const {
  flow,
  // align,
  // indent
} = transforms;
const transform = flow();
type Tag = {
  tagName: string;
  type?: string;
  name?: string;
  description?: string;
};
export class ScriptAnnotationsHelper {
  readonly allCommentBlocks;
  private mainBlock;
  private existingMainCommentLines = {
    start: 0,
    end: 0,
  };
  private tagsAdded: Tag[] = [];
  private tagsRemoved: Tag[] = [];

  constructor(public readonly script: string) {
    this.allCommentBlocks = parseComments(script);

    // if there is an existing comment block, we want to add tags to that
    // instead of creating a new comment block. However, we are skipping
    // existing one-line comment blocks to make it easier to add tags later.
    if (this.allCommentBlocks[0]?.source?.length > 1) {
      this.mainBlock = this.allCommentBlocks[0];
      this.mainBlock.source.sort((a, b) => a.number - b.number);

      const sourceLines = this.mainBlock.source;
      this.existingMainCommentLines = {
        start: sourceLines[0].number,
        end: sourceLines[sourceLines.length - 1].number,
      };
    } else {
      this.mainBlock = util.seedBlock(emptyCommentBlock());
    }
  }

  containsTag(tagName: string, type: string, name?: string): boolean {
    const alreadyAdded = this.tagsAdded.some(
      (tag) =>
        tag.tagName === tagName &&
        tag.type === type &&
        (name ? tag.name === name : true)
    );
    if (alreadyAdded) {
      return true;
    }

    for (const block of this.allCommentBlocks) {
      if (block.problems.length > 0) {
        continue;
      }
      for (const tag of block.tags) {
        if (tag.problems.length > 0) {
          continue;
        }

        if (
          tag.tag === tagName &&
          tag.type === type &&
          (name ? tag.name === name : true)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  addTag(
    tagName: string,
    type: string,
    options?: { name?: string; lineNumber?: number; blankLinesBefore?: number }
  ): void {
    this.tagsAdded.push({ tagName, type, name: options?.name });
    let lineNumber = options?.lineNumber ?? this.mainBlock.source.length - 1;

    for (let i = 0; i < (options?.blankLinesBefore ?? 0); i++) {
      this.mainBlock.source.splice(lineNumber, 0, {
        number: -1, //updated later
        source: "", // not needed
        tokens: {
          start: " ",
          delimiter: "*",
          postDelimiter: " ",
          tag: "",
          postTag: "",
          name: "",
          postName: "",
          type: "",
          postType: "",
          description: "",
          end: "",
          lineEnd: "",
        },
      });
      lineNumber++;
    }

    this.mainBlock.source.splice(lineNumber, 0, {
      number: -1, //updated later
      source: "", // not needed
      tokens: {
        start: " ",
        delimiter: "*",
        postDelimiter: " ",
        tag: `@${tagName}`,
        postTag: " ",
        name: options?.name ? ` ${options?.name}` : "",
        postName: "",
        type: `{${type}}`,
        postType: "",
        description: "",
        end: "",
        lineEnd: "",
      },
    });
  }

  removeTag(tagName: string, type?: string, name?: string): void {
    const indexOfTag = this.mainBlock.source.findIndex(
      ({ tokens }) =>
        tokens.tag === `@${tagName}` &&
        (type ? tokens.type === `{${type}}` : true) &&
        (name ? tokens.name === name : true)
    );

    if (indexOfTag !== -1) {
      this.tagsRemoved.push({ tagName, type, name });
      this.mainBlock.source.splice(indexOfTag, 1);
    }
  }

  getUpdatedScript(): string {
    const annotationsNeedUpdating =
      this.tagsAdded.length > 0 || this.tagsRemoved.length > 0;
    if (annotationsNeedUpdating) {
      const commentHasTags = this.mainBlock.source.some((line) =>
        Boolean(line.tokens.tag?.trim())
      );
      if (!commentHasTags) {
        // delete comment if all tags have been removed
        return this.scriptWithReplacedComment();
      }

      return this.scriptWithReplacedComment(this.getUpdatedCommentLines());
    } else {
      return this.script;
    }
  }

  private scriptWithReplacedComment(newCommentLines: string[] = []) {
    const lines = this.script.split("\n");
    const { start, end } = this.existingMainCommentLines;

    lines.splice(
      start,
      end === start ? 0 : 1 + end - start,
      ...newCommentLines
    );

    return lines.join("\n");
  }

  private getUpdatedCommentLines() {
    const mainBlock = structuredClone(this.mainBlock);
    // make sure line numbers are correct
    mainBlock.source.forEach((line, index) => {
      line.number = index + 1;
    });

    let commentBlockString = stringify(transform(mainBlock));
    if (this.existingMainCommentLines.end === 0) {
      // this is a new comment block, not an update to an existing one.
      commentBlockString += "\n"; // add an extra whitespace line after the comment block
    }
    return commentBlockString.split("\n");
  }
}

const emptyCommentBlock = () => ({
  source: [
    {
      number: 0,
      source: "/**",
      tokens: {
        start: "",
        delimiter: "/**",
        postDelimiter: "",
        tag: "",
        postTag: "",
        name: "",
        postName: "",
        type: "",
        postType: "",
        description: "",
        end: "",
        lineEnd: "",
      },
    },
    {
      number: 1,
      source: " */",
      tokens: {
        start: " ",
        delimiter: "",
        postDelimiter: "",
        tag: "",
        postTag: "",
        name: "",
        postName: "",
        type: "",
        postType: "",
        description: "",
        end: "*/",
        lineEnd: "",
      },
    },
  ],
});
